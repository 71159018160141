.cardContainer {
  width: 236.092px;
  height: 263.545px;
  flex-shrink: 0;

  background: #fff;
  border-radius: 5px;
  outline: 1px solid rgba(226, 229, 237, 0.5);
  box-shadow: 0 0 14px rgba(0, 0, 0, 0.1);

  padding: 18px 15px 30px 15px;
  display: flex;
  flex-direction: column;
  gap: 33px;
  justify-content: center;
  align-items: center;
  transition: outline 0.2s, transform 0.2s ease-in-out;
}

.cardContainer:hover {
  cursor: pointer;
  transform: scale(1.04);
}

.cardImage {
  width: 206px;
  height: 166px;
  object-fit: cover;
  flex-shrink: 0;
  background-color: rgba(245, 245, 245, 0.7);
  border-radius: 4px;
}

.cardTitle {
  color: #113357;
  text-align: center;
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 15px;
  letter-spacing: 0.171px;
}

.selected {
  outline: 4px solid #b3d0fe;
}

.selected:hover {
  outline: 4px solid #b3d0fe;
}

@media (max-width: 1000px) {
  .cardContainer {
    width: 180px;
    height: 200px;
  }

  .cardImage {
    width: 150px;
    height: 120px;
  }

  .cardTitle {
    font-size: 18px;
    line-height: 15px;
  }
}
