.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
}

.inputContainer {
  display: flex;
  align-items: flex-end;
  width: 100%;
}

.successHeader {
  text-align: center;
  font-family: Sora, sans-serif;
  font-size: 18px;
  font-weight: 700;
  padding: 36px 20px 10px 20px;
}

.successBody {
  text-align: center;
  font-family: Poppins, sans-serif;
  font-size: 14px;
  min-width: 380px;
}

.successButtonContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
}

.strip {
  font-family: Poppins, Roboto, Arial, sans-serif;
  font-size: 14px;
  font-weight: 500;
  display: flex;
  gap: 8px;
}

.successStrip {
  composes: strip;
  background: rgba(191, 237, 220, 0.3);
  color: #00b875;
  padding: 8px 14px;
  margin: 8px 0;
}

.failedContainer {
  width: 100%;
}

.failedStrip {
  composes: strip;
  background: rgba(255, 217, 205, 0.35);
  color: #f8746b;
  display: flex;
  flex-direction: column;
  padding: 8px 14px;
}

.failedEmails {
  max-height: 167px;
  overflow-y: auto;
}

.failedEmailRow {
  composes: strip;
  color: #848199;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0.219px;
}

.failedMessage {
  color: #f8746b;
  font-size: 12px;
  padding: 12px 0;
}

.failedMessageContainer {
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.fieldContainer {
  flex-grow: 1;
  margin-right: 10px;
}

.invitationFailed {
  display: flex;
  gap: 10px;
}
