.container {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  display: grid;
  grid-template-rows: auto;
  grid-template-columns: 1fr 53.17%;
  grid-template-areas: "left-section right-section";
  width: 838px;
}

.sectionColumn {
  display: flex;
  flex-direction: column;
}

.leftSection {
  composes: sectionColumn;
  grid-area: left-section;
  background: white;
  min-width: 395px;
}

.rightSection {
  display: flex;
  align-items: center;
  justify-content: center;
  composes: sectionColumn;
  grid-area: right-section;
  background: #f6f9fc;
  border-left: 0.612028px solid #cbcfd2;
}

.rightSectionContent {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.rightSectionContent > img {
  width: 100%;
  height: auto;
}

.timeLineContainerContent {
  margin-left: 36px;
  margin-top: 61px;
}

.checkoutWrapper {
  display: flex;
  flex-direction: column;
  align-items: baseline;
  padding: 33px;
  justify-content: space-between;
}

.checkoutHeader {
  font-family: "Sora";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 30px;
  letter-spacing: 0.152063px;
  color: #113357;
  padding-bottom: 5px;
}

.checkoutSubtitle {
  color: #113357;
  font-family: "Poppins";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  padding-bottom: 15px;
  display: flex;
  gap: 9px;
}

.infoContainer {
  display: flex;
  flex-direction: column;
  gap: 9px;
}

.infoLine {
  display: flex;
  align-items: center;
  gap: 12.5px;
}

.infoLineText {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  display: inline-flex;
  align-items: center;
  color: #113357;
}

.rightSectionTitle {
  color: #113357;
  font-size: 14px;
  font-family: Sora;
  font-style: normal;
  font-weight: 600;
  line-height: 40.041px;
  position: relative;
  top: 12px;
}

.divider {
  background: #dadce0;
  height: 1px;
  width: 100%;
  margin: 19px 0;
}

.buttonsContainer {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
