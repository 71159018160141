.onboarding {
  background: white;
  width: 100%;
  height: 100%;

  --font-family-h: "Sora";
  --font-family-p: "Poppins";
}

.onboarding h1,
.onboarding h2,
.onboarding h3,
.onboarding h4,
.onboarding h5,
.onboarding h6 {
  margin: 0;
  font-family: var(--font-family-h);
  color: var(--text, "#113357");
}

.onboarding h1 {
  font-size: 2em;
  font-weight: 700;
}

.onboarding p {
  font-family: var(--font-family-p);
  font-size: 14px;
  font-weight: 400;
  line-height: 150%;
  text-align: left;
}

.twoColumns {
  display: grid;
  grid-template: 1fr / 1fr minmax(0, 1fr);
  align-items: center;
  overflow: hidden;
}

.main {
  display: flex;
  flex-direction: column;
  gap: 2em;
  place-items: center;
  margin: auto;
  padding: 20px;
}

.sidebar {
  min-inline-size: 0;
  background: #f4f7fa;
  display: flex;
  flex-direction: column;
  align-self: stretch;
  align-items: flex-end;
  justify-content: center;
  overflow: hidden;
}

.container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  min-height: 400px;
  color: #113357;
}

.imageContainer {
  box-shadow: 0px 3px 30px 0px #1973ff33;
  display: grid;
  place-items: center;
  height: auto;
  width: 80%;
}
.imageContainer > * {
  grid-area: 1/1;
}

.image {
  width: 100%;
  height: auto;
}

.orgChartViewGroup {
  display: grid;
  grid-template: auto / repeat(2, 250px);
  gap: 1em;
  width: 100%;
  place-content: space-between;
}

.hide {
  appearance: none;
  margin: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  display: inline;
}

.radio {
  display: inline-block;
  width: 1rem;
  height: 1rem;
  vertical-align: middle;
  background: inherit;
  border-radius: 50%;
  border: 2px solid #dadce0;
  outline: none;
  transition: border-color 0.2s ease-out;
  position: relative;
  margin-right: 0.5em;
}

.radio[data-checked="true"] {
  border-color: var(--selected);
}

.radio:after {
  content: "";
  display: block;
  margin: auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: calc(100% - 4px);
  width: calc(100% - 4px);
  border-radius: 50%;
  background-color: inherit;
  transition: background-color 0.2s ease-out, border-color 0.2s ease-out;
}

.radio[data-checked="true"]:after {
  background-color: var(--selected);
}

.cardOutline {
  background: none;
  border: 1.5px solid #dadce0;
  display: grid;
  place-items: center;
  grid-template-rows: auto 1rem;
  grid-template-areas:
    "content"
    "label";
  transition: border-color 0.2s ease-out;
  cursor: pointer;
  height: 100%;
  padding: 1em;
}

.cardOutline:hover {
  border-color: #a3a5a8;
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.15);
}

input[type="radio"]:checked ~ .cardOutline {
  border-color: var(--selected);
}

input[type="radio"]:focus ~ .cardOutline {
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.15);
}

input[type="radio"]:disabled ~ .cardOutline {
  color: #a3a5a8;
  cursor: default;
}

.subtitle {
  color: #113357;
  font-family: Poppins;
  font-style: normal;
  font-size: calc(0.2em + 0.5vw + 0.5vh);
  font-weight: 600;
  line-height: normal;
}

.minLineSize {
  min-inline-size: 550px;
}

.header {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.inviteContent {
  flex-direction: column;
  gap: clamp(8px, 2vh, 42px);
}

.inviteButtons {
  padding: 2px;
  width: 80%;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  grid-gap: 8px;
  gap: 8px;
}

.title {
  font-family: Sora, sans-serif;
  font-size: calc(0.4em + 1vw + 1vh);
  font-weight: 500;
  text-align: center;
}

.emailInvite {
  padding: 2px;
  width: 100%;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  gap: 17px;
}

.flexStart {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: clamp(8px, 2vh, 32px);
}

.inviteButtonContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  grid-gap: 12px;
  gap: 12px;
  width: 100%;
}

.skipButton {
  font-size: calc(0.2em + 0.5vw + 0.5vh);
  font-weight: 400;
  color: #1973ff;
  padding: 2px 12px;
}

.skipButton:hover {
  cursor: pointer;
  opacity: 0.5;
}

.inputContainer {
  flex-grow: 1;
  padding-right: 17px;
}
