.centerScreen {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  min-height: 100vh;
}

.mainLayout {
  position: relative;
  width: 725px;
  display: grid;
  grid-template-columns: 4fr 5.6fr;
  box-shadow: 0px 7px 25px 0px #0000004d;
  background-color: white;
}
.mainLayout > * {
  height: 415px;
}

.modal {
  color: var(--text);
  padding: 25px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  font-size: 14px;
  font-weight: 400;
}

/* reset padding and margin for h1,h2, section */
.modal > * {
  padding: 0;
  margin: 0;
  text-align: start;
}

.modal h1 {
  font-family: Sora;
  font-size: 24px;
  font-weight: 700;
  line-height: 30px;
  letter-spacing: 0.15px;
  text-align: left;
  color: var(--text);
  margin: 0;
}

.modal h2 {
  color: var(--text);
  font-family: Sora;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  letter-spacing: 0.152px;
}

.modal p {
  color: var(--text);
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.modal a {
  color: #1973ff;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 19px;
  text-decoration-line: underline;
}

.modalImage {
  background-color: #f4f7fa;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

/********************  Close button **********************/

.closeButton {
  position: absolute;
  top: 14px;
  right: 14px;

  border-radius: 50%;
  padding: 0.75ch;
  width: 1.5rem;
  height: 1.5rem;
  display: grid;
  place-content: center;
  font-size: 1em;
  line-height: 1;
  outline: none;
  border: none;
  background: none;
  cursor: pointer;
  color: #848199;
}

.closeButton:hover {
  color: var(--dark-blue);
}

.closeButton::after {
  content: "\2715";
}
