.container {
  position: fixed;
  left: 50%;
  transform: translate(-50%, -50%);
  bottom: 26px;
  width: fit-content;
  background: #113357;
  box-shadow: 0px 6px 20px rgba(0, 0, 0, 0.2);
  animation: slide-up 0.6s cubic-bezier(0.4, 0, 0.2, 1) forwards;
  border-radius: 6px;
  z-index: 999;
}

.content {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 16px;
  color: #fff;
}

@keyframes slide-up {
  0% {
    bottom: 0px;
    transform: translate(-50%, 100%);
  }

  100% {
    bottom: 14px;
    transform: translate(-50%, -50%);
  }
}

.toastText {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #ffffff;
}

.tryNow {
  color: #a7c9ff;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  cursor: pointer;
  margin-left: 8px;
}
