.container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-grow: 1;
  background-color: var(--dark);
  padding: 0px 20px 0 0;
  height: 100%;
}

.mask {
  height: 100%;
  position: relative;
}

.pinkCursor {
  /* animation: xPink 6s infinite ease-in; */
}

.pinkCursor::after {
  content: url("/images/invite-notification-pink-cursor.svg");
  position: absolute;
  right: 0;
  transform-origin: 50% 50%;
  animation-timing-function: linear;
  /* animation: yPink 6s infinite ease-out; */
}

@keyframes xPink {
  50% {
    transform: translateX(20px);
  }
}

@keyframes yPink {
  50% {
    transform: translateY(-12px);
  }
}

.greenCursor {
  /* animation: xGreen 10s infinite ease-in; */
}

.greenCursor::after {
  content: url("/images/invite-notification-green-cursor.svg");
  position: absolute;
  right: 0;
  transform-origin: 50% 50%;
  animation-timing-function: linear;
  /* animation: yGreen 10s infinite ease-out; */
}

@keyframes xGreen {
  50% {
    transform: translateX(-10px);
  }
}

@keyframes yGreen {
  50% {
    transform: translateY(12px);
  }
}

.redCursor {
  /* animation: xRed 6s infinite ease-out; */
}

.redCursor::after {
  content: url("/images/invite-notification-red-cursor.svg");
  position: absolute;
  right: 0;
  /* animation: yRed 6s infinite ease-in; */
}

@keyframes xRed {
  50% {
    transform: translateX(-6px);
  }
}

@keyframes yRed {
  50% {
    transform: translateY(-8px);
  }
}

.yellowCursor {
  /* animation: xYellow 5s infinite ease-in; */
}

.yellowCursor::after {
  content: url("/images/invite-notification-yellow-cursor.svg");
  position: absolute;
  left: 0;
  transform-origin: 50% 50%;
  animation-timing-function: linear;
  /* animation: yYellow 5s infinite ease-out; */
}

@keyframes xYellow {
  50% {
    transform: translateX(-20px);
  }
}

@keyframes yYellow {
  50% {
    transform: translateY(12px);
  }
}

.fuchsiaCursor {
  /* animation: xFuchsia 7s infinite ease-in; */
}

.fuchsiaCursor::after {
  content: url("/images/invite-notification-fuchsia-cursor.svg");
  position: absolute;
  left: 0;
  transform-origin: 50% 50%;
  animation-timing-function: linear;
  /* animation: yFuchsia 7s infinite ease-out; */
}

@keyframes xFuchsia {
  50% {
    transform: translateX(-20px);
  }
}

@keyframes yFuchsia {
  50% {
    transform: translateY(-6px);
  }
}

.purpleCursor {
  /* animation: xPurple 6s infinite ease-out; */
}

.purpleCursor::after {
  content: url("/images/invite-notification-purple-cursor.svg");
  position: absolute;
  left: 0;
  /* animation: yPurple 6s infinite ease-in; */
}

@keyframes xPurple {
  50% {
    transform: translateX(-30px);
  }
}

@keyframes yPurple {
  50% {
    transform: translateY(-5px);
  }
}

.orangeCursor {
  /* animation: xOrange 6s infinite ease-in; */
}

.orangeCursor::after {
  content: url("/images/invite-notification-orange-cursor.svg");
  position: absolute;
  left: 0;
  /* animation: yOrange 6s infinite ease-out; */
}

@keyframes xOrange {
  50% {
    transform: translateX(-10px);
  }
}

@keyframes yOrange {
  50% {
    transform: translateY(-10px);
  }
}

@keyframes downSlide {
  0%,
  100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(10px);
  }
}

@keyframes upRightSlide {
  0%,
  100% {
    transform: translate(0, 0);
  }
  40% {
    transform: translate(10px, -10px);
  }
}

@keyframes upLeftSlide {
  0%,
  100% {
    transform: translate(0, 0);
  }
  40% {
    transform: translate(-5px, -15px);
  }
}

@keyframes rightSlide {
  0%,
  100% {
    transform: translateX(0);
  }
  40% {
    transform: translateX(10px);
  }
}

@keyframes downLeftSlide {
  0%,
  100% {
    transform: translate(0, 0);
  }
  40% {
    transform: translate(-10px, -5px);
  }
}

.centerContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  gap: 1rem;
  z-index: 100;
}

.text {
  font-size: 0.875rem;
  font-weight: 300;
}

.bold {
  font-weight: 600;
}
