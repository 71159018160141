.buttonWrapper {
  position: relative;
  display: inline-block;
}

.container:hover {
  cursor: pointer;
}

.button {
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  gap: 6px;
  color: #113357;
  text-align: center;
  font-family: Sora;
  font-size: 13px;
  font-weight: 500;
  cursor: pointer;
  margin-top: -6px;
}

.saveAsTemplateWrapper {
  margin-bottom: -2px;
}

.buttonText {
  padding-right: 6px;
}

.option {
  display: flex;
  align-items: center;
  cursor: pointer;
  justify-content: space-between;
  padding: 10px 10px 10px 14px;
  color: #113357;
  font-family: "Poppins", sans-serif;
  font-size: 12px;
  font-weight: 400;
}

.option:hover {
  background-color: #e6f1ff;
}

.type {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 9px;
}

.optionsContainer {
  position: absolute;
  top: 100%;
  left: 50%;
  width: 174px;
  transform: translateX(-50%);
  background-color: white;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  z-index: 1;
  margin-top: 11px;
}

.infoIconWrapper {
  position: relative;
  display: inline-block;
  top: 3px;
}

.tooltip {
  position: absolute;
  top: calc(100% + 10px);
  left: 70%;
  transform: translateX(-70%);
  color: white;
  padding: 12px;
  border-radius: 3px;
  z-index: 2;
  background: #113357;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
  color: #fff;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
}

.tooltipArrow {
  position: absolute;
  top: -5px;
  left: 70%;
  transform: translateX(-70%);
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 5px solid #2f3136;
}
