.offlineIconContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.offlineIconContainer:hover .toastContainer {
  visibility: visible;
  opacity: 1;
}

.toastContainer {
  visibility: hidden;
  opacity: 0;
  position: absolute;
  top: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  background: #113357;
  text-align: center;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px;
  padding: 16px;
  white-space: nowrap;
  border-radius: 6px;
}

.toastContainer::before {
  content: "";
  display: block;
  position: absolute;
  transform: translateY(-20px) rotate(45deg);
  width: 10px;
  height: 10px;
  background: #113357;
}
