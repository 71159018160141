.container {
  z-index: 999;
  position: absolute;
  background-color: white;
  top: calc(100% + 5px);
  left: 0;
  width: max-content;
  min-width: 100%;
  box-shadow: 0px 7px 25px rgba(0, 0, 0, 0.3);
  padding: 8px;
  max-height: 396px;
  overflow: auto;

  display: flex;
  flex-direction: column;
}

.container.dark {
  background-color: #113357;
  color: white;
}
