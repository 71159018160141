.gridContainer {
  display: flex;
  flex-direction: column;
  gap: 6px;
  cursor: pointer;
  min-width: 191px;
  max-width: 191px;
  align-items: stretch;
}

.gridContainer.small {
  min-width: 160px;
  max-width: 160px;
}

.titleContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
}

.proBadge {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 20px;
  width: 30px;
  background-color: rgba(245, 181, 61, 0.2);
}

.templateTitle {
  font-size: 14px;
  font-weight: 600;
  color: var(--dark);
  text-overflow: ellipsis;
  overflow: hidden;
  /* height: 1.2em; */
  white-space: nowrap;
}

.templateImage {
  position: relative;
  background: white;
  border: 0.5px solid #dadce0;
  width: 100%;
  height: 141px;
  transition: all 100ms;
}

.templateImage.small {
  height: 118px;
}

.templateImage img:not(.mondayLogo) {
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 100%;
  max-height: 100%;
}

.templateImage.small:hover {
  box-shadow: 0px 24px 48px -8px #0b264233;
}

.buttons {
  position: absolute;
  inset: 0;
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
  justify-content: center;
  background: rgba(255, 255, 255, 0.9);
  opacity: 0;
}

.buttons:hover {
  opacity: 1;
}

.mondayLogo {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 20px;
}

.templateOwnerActions {
  display: flex;
  gap: 8px;
}

.templateOwnerActions span:hover {
  cursor: pointer;
  color: #1973ff;
}

.menuIcon {
  position: absolute;
  top: 2px;
  right: 8px;
  width: 15px;
  height: 15px;
  background: #848199;
  -webkit-mask: url(/images/menu-icon.svg) no-repeat center;
  mask: url(/images/menu-icon.svg) no-repeat center;
  mask-size: contain;
}

.menuIcon:hover {
  background: var(--blue);
}
