.toolbar {
  color: white;
  height: 100%;
  display: flex;
  padding: 0px 20px;
  gap: 8px;
  align-items: center;
  font-size: 14px;
  font-weight: 300;
  flex-grow: 1;
}

.toolBarWarning {
  composes: toolbar;
  background-color: #ff3b77;
}

.toolbar img {
  width: 16px;
}

.toolbar .description {
  color: #b7bbc7;
  white-space: nowrap;
}

.toolBarWarning .description {
  color: #ffffff;
  white-space: nowrap;
}

.moreInfo {
  text-decoration: underline;
  white-space: nowrap;
  cursor: pointer;
}
