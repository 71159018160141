.mobileContainer {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 20px;
  align-items: center;
  text-align: center;
  font-size: 16px;
  background-color: #fff;
}

.logo {
  width: 80%;
  padding: 40px 80px;
}

.bottomContainer {
  width: 100%;
}

.mobileContentTitle {
  color: #113357;
  text-align: center;
  font-family: Sora;
  font-size: 6vw;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  padding: 0 40px;
}

.mobileContentBody {
  color: #113357;
  text-align: center;
  font-family: Poppins;
  font-size: 4vw;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  width: 80%;
  padding: 40px;
}
