.optionIcon {
  width: 17px;
  height: 17px;
}

.headerIcon {
  color: #848199;
}

.headerIcon:hover {
  color: #6c6882;
}

.buttonWrapper {
  position: relative;
  display: inline-block;
}

.container {
  cursor: pointer;
}

.button {
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  gap: 6px;
  color: #113357;
  text-align: center;
  font-family: Sora;
  font-size: 13px;
  font-weight: 500;
  cursor: pointer;
  margin-top: -6px;
}

.dropdownWrapper {
  margin-bottom: -2px;
}

.optionsContainer {
  position: absolute;
  top: 100%;
  left: 50%;
  width: 150px;
  transform: translateX(-85%);
  background-color: white;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  z-index: 1;
  margin-top: 11px;
  cursor: pointer;
}

.option {
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 10px 10px 10px 14px;
  color: #113357;
  font-family: "Poppins", sans-serif;
  font-size: 12px;
  font-weight: 400;
  cursor: pointer;
}

.option:hover {
  background-color: #e6f1ff;
}
