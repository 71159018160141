.toolbar {
  background-color: var(--dark);
  color: white;
  height: 100%;
  display: flex;
  padding: 0px 20px;
  gap: 8px;
  align-items: center;
  font-size: 14px;
  font-weight: 200;
  flex-grow: 1;
}

.alertIcon {
  color: white;
  fill: currentColor;
}

.toolbar .description {
  color: #b7bbc7;
  white-space: nowrap;
  margin-right: 2px;
}

.toolbar .link {
  color: white;
  text-decoration: underline;
  white-space: nowrap;
}

.toolbar .link:hover {
  cursor: pointer;
  opacity: 0.5;
}

.toolBarWarning {
  composes: toolbar;
  background-color: #e03e1a;
}

.toolBarWarning .description {
  color: #ffffff;
  white-space: nowrap;
}

.moreInfo {
  text-decoration: underline;
  white-space: nowrap;
  cursor: pointer;
}

.link {
  font-weight: 400;
}
