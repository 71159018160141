.container {
  display: flex;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  margin: auto;
  min-width: 529px;
  width: 29vw;
  max-width: 893px;
  height: fit-content;
  max-height: 90vh;
  background-color: white;
}

.internalContainer {
  min-width: 550px;
  box-shadow: 0px 7px 25px rgba(0, 0, 0, 0.3);
  background-color: white;
  gap: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: var(--dark);
}

.successHeader {
  text-align: center;
  font-family: Sora, sans-serif;
  font-size: 1.125rem;
  font-weight: 700;
  padding: 36px 20px 10px 20px;
}

.successBody {
  text-align: center;
  font-family: Poppins, sans-serif;
  font-size: 0.875rem;
  min-width: 380px;
}

.successButtonContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
}

.failedContainer {
  padding: 0 26px;
}
