.container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  min-height: 400px;
  min-width: 700px;
  gap: 32px;
  color: #113357;
}

.title {
  font-family: Sora, sans-serif;
  font-size: 30px;
  font-weight: 700;
  text-align: center;
}

.newTitle {
  font-family: Sora, sans-serif;
  font-size: 30px;
  font-weight: 500;
  text-align: center;
}

.title img {
  width: 18px;
  height: 18px;
}

.content {
  display: flex;
  min-height: 250px;
  width: 100%;
  max-width: 600px;
  align-items: center;
  align-content: center;
  justify-content: center;
}

.blocks {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: stretch;
  align-content: center;
  gap: 5%;
  height: 90%;
  min-height: 250px;
}

.block {
  display: flex;
  flex-direction: column;
  border: 2px solid #dadce0;
  justify-content: flex-end;
  align-items: stretch;
  padding: 14px 20px;
  aspect-ratio: 0.78;
  max-height: 330px;
  min-width: 256px;
}

.block img {
  max-width: 100%;
}

.block.selected {
  border: 2px solid #1973ff;
}

.imageContainer {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
}

.block:hover {
  cursor: pointer;
  background: #f2f8ff;
}

.blockTitle {
  display: flex;
  flex-direction: row;
  gap: 16px;
  align-items: center;
  font-size: 1.1em;
  font-weight: 600;
}

@media only screen and (max-height: 560px) {
  .blockTitle {
    font-size: 1em;
  }
}

.radio {
  position: relative;
  width: 12px;
  height: 12px;
  border-radius: 6px;
  outline: none;
}

.radio::before {
  content: "";
  position: absolute;
  top: -4px;
  right: -4px;
  bottom: -4px;
  left: -4px;
  border: 2px solid #dadce0;
  border-radius: 10px;
}

.radio.selected {
  background: #1973ff;
}

.radio.selected::before {
  content: "";
  position: absolute;
  top: -4px;
  right: -4px;
  bottom: -4px;
  left: -4px;
  border: 2px solid #1973ff;
  border-radius: 10px;
}

.inviteButtons {
  width: 470px;
  display: flex;
  flex-direction: column;
  gap: 22px;
  align-items: flex-start;
  gap: 12px;
}

.sourceButton {
  background: #1973ff;
  display: flex;
  flex-direction: row;
  justify-content: center;
  height: 40px;
  border-radius: 3px;
  padding: 1px 12px 1px 1px;
  gap: 12px;
}

.sourceButton .buttonContainer {
  width: 36px;
  background: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 2px 0 0 2px;
}

.sourceButton img {
  width: 18px;
  height: 18px;
}

.sourceButton:hover {
  cursor: pointer;
  opacity: 0.5;
}

.buttonTitle {
  font-family: Roboto, serif;
  font-size: 14px;
  font-weight: 500;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}

.emailInvite {
  padding: 2px;
  width: 80%;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.contactsList {
  width: 470px;
  /*max-height: 348px;*/
  min-height: 200px;
  overflow-y: auto;
  border: 1px solid #dadce0;
}

.contactRow {
  display: flex;
  height: 60px;
  flex-direction: row;
  align-items: center;
  gap: 12px;
  padding: 12px;
  font-size: 14px;
  font-weight: 600;
}

.contactRow:hover {
  cursor: pointer;
  background: #f8f8f8;
}

.contactRow .contactImage {
  width: 36px;
  height: 36px;
  border-radius: 18px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 18px;
  font-weight: 400;
}

.searchFieldContainer {
  width: 470px;
}

.inviteContent {
  flex-direction: column;
  gap: 42px;
}

.inviteSourceContent {
  flex-direction: column;
  gap: 10px;
}

.inviteButtonContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
}

.skipButton {
  font-size: 14px;
  font-weight: 400;
  color: #1973ff;
  padding: 2px 12px;
}

.skipButton:hover {
  cursor: pointer;
  opacity: 0.5;
}

.emptyContacts {
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 20px;
  font-size: 14px;
}

.emptyContacts a {
  color: #1973ff;
}

.emptyContacts a:hover {
  opacity: 0.5;
}
