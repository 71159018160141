.container {
  display: flex;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  background: white;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.content {
  display: flex;
  width: 100%;
}

.leftSection {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-width: 651px;
  height: 100vh;
  overflow-y: hidden;
}

.checkoutContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  overflow-y: auto;
  max-height: 401px;
  flex-grow: 1;
}

.choosePlanContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  overflow-y: auto;
  flex-grow: 1;
  max-height: 570px;
}

.checkoutContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 60px;
}

.title {
  font-family: "Sora";
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 40px;
  letter-spacing: -0.132266px;
  color: #113357;
}

.description {
  margin-top: 20px;
  max-width: 403px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #113357;
  text-align: center;
}

.rightSection {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #f8fafd;
  border: 0.711458px solid #dadce0;
  min-width: 651px;
}

.rightSectionContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 49px;
}

.rightSectionTitle {
  font-family: "Sora";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 40px;
  color: #113357;
  margin-bottom: 29px;
}

.image {
  /* width: 80%;*/
}

.plansSelectionContainer {
  display: flex;
  flex-direction: column;
}

.optionsWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: fit-content;
  border-bottom: 1px solid #dadce0;
  padding-bottom: 25px;
}

.options {
  display: flex;
  flex-direction: column;
  gap: 21px;
  margin-top: 21px;
  min-height: 230px;
}

.optionWrapper {
  display: flex;
  flex-direction: column;
  gap: 6px;
}

.planDescription {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 37px;
  letter-spacing: -0.121191px;
  color: #113357;
}

.option {
  position: relative;
  display: flex;
  align-items: center;
  gap: 13px;
  width: 546px;
  height: 57px;
  background: #ffffff;
  border: 1.5px solid #dadce0;
  padding-left: 19px;
  cursor: pointer;
}

.selectedOption {
  composes: option;
  border: 1.5px solid #1973ff;
}

.optionButton {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 19px;
  height: 19px;
  border-radius: 50%;
  background: #ffffff;
  border: 1.5px solid #dadce0;
}

.selectedOptionButton {
  composes: optionButton;
  border: 1.5px solid #1a73ff;
}

.internalSelectedOptionButton {
  width: 11.88px;
  height: 11.88px;
  border-radius: 50%;
  background: #1a73ff;
}

.optionTextContainer {
  display: flex;
  align-items: baseline;
  justify-content: center;
  gap: 10px;
}

.optionText {
  font-family: "Sora";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 23px;
  letter-spacing: 0.3px;
  color: #113357;
}

.optionDetailsText {
  font-family: "Sora";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 24px;
  text-align: right;
  color: #848199;
}

.discountLabel {
  position: absolute;
  top: -13px;
  right: -11px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 88px;
  height: 24px;
  background: #00b875;

  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #ffffff;
}

.secureCheckout {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  margin-top: 27px;

  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  display: flex;
  align-items: center;
  letter-spacing: 0.271778px;
  color: #848199;
}

.seatsContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 11px;
  border-top: 1px solid #dadce0;
  border-bottom: 1px solid #dadce0;
  margin-top: 25px;
  min-height: 76px;
  width: 100%;

  font-family: "Sora";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 37px;
  letter-spacing: -0.121191px;
  color: #113357;
}

.seatsInput {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 73.85px;
  height: 38.15px;
  background: #ffffff;
  border: 1.246px solid #dadce0;
  outline: none;

  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.262692px;
  color: #848199;
}

.seatsInput::placeholder {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.262692px;
  color: #848199;
}

/* Chrome, Safari, Edge, Opera */
.seatsInput::-webkit-outer-spin-button,
.seatsInput::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.seatsInput[type="number"] {
  -moz-appearance: textfield;
}

.footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 30px;
  width: 546px;
  margin: 25px 0;
}

.continueButton {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 212px;
  height: 36px;
  background: #1973ff;
  outline: none;
  border: none;
  cursor: pointer;

  font-family: "Sora";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.124302px;
  color: #ffffff;
  z-index: 1000;
}

.continueButton:hover {
  opacity: 0.9;
}

.minSeatsContainer {
  display: flex;
  justify-content: center;
  width: 515px;
  height: 92px;
  background: #f7fafc;
  border: 1px solid #e3e8ee;

  font-family: "Sora";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  display: flex;
  align-items: center;
  color: rgba(60, 66, 87, 0.9);
}

.dropDownContainer {
  font-family: "Sora";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 13px;
  display: flex;
  align-items: center;
  letter-spacing: 0.219412px;
  color: #113357;
}

.stepperContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
}

.step {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: #d0d0d9;
}

.currentstep {
  composes: step;
  background: #1973ff;
}

.subTotalContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.subTotalLines {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
}

.subTotalDetails {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 7px;
}

.subTotalTitle {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: #113357;
}

.subTotalPrice {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  text-align: right;
  color: #113357;
}

.cancellationNoteContainer {
  display: flex;
  align-items: center;
  padding-left: 19px;
  background: #f8fafd;
  width: 100%;
  height: 44px;
}

.cancellationNoteText {
  position: relative;
  left: 12px;

  color: #113357;
  font-size: 14px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.219px;
}

.checkoutSkeletonContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 35px;
}

.paymentMessage {
  color: rgb(105, 115, 134);
  font-size: 14px;
  line-height: normal;
  text-align: center;
  color: red;
  font-family: Poppins;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0.219px;
  min-height: 54px;
  max-height: fit-content;
  padding-top: 20px;
}

.ctaButton {
  z-index: 999;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.completePurchaseButtonContainer {
  z-index: 999;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-bottom: 79px;
}

@media (max-height: 750px) {
  .leftSection {
    padding-top: 5%;
    justify-content: flex-start;
  }
  .ctaButton {
    border-top: 1px solid #dadce0;
    box-shadow: 0 -10px 15px -5px #dadce0;
    margin: 0;
    height: 100px;
    padding: 20px 0;
  }
}

@media (max-height: 600px) {
  .completePurchaseButtonContainer {
    border-top: 1px solid #dadce0;
    box-shadow: 0 -10px 15px -5px #dadce0;
    padding-bottom: 0;
    margin: auto;
  }

  .completePurchaseButton {
    margin: 30px 0;
  }
}
