.container {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  pointer-events: visible;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.200691);
  background-color: white;
  display: flex;
  flex-direction: row;
  align-items: stretch;
  width: 919px;
  height: 597px;
}

.closeIcon {
  position: absolute;
  cursor: pointer;
  top: 13px;
  right: 13px;
  width: 16px;
  height: 16px;
}

.closeIcon:hover {
  background: #eff1f5;
  border-radius: 4px;
}

.previewContainer {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: center;
  gap: 8px;
  border-radius: 10px 0 0 10px;
  background: #f6f9fc;
  width: 50%;
  padding: 100px 40px;
  background-image: url("/images/BG-DOT.png");
  background-size: cover;
}

.previewContainer img {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.200691);
  width: 100%;
  height: 60%;
  object-fit: contain;
  background: white;
}

.previewContainer span {
  color: #1973ff;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.fieldsContainer {
  width: 50%;
  padding: 36px;
  border-left: 0.5px solid #cbcfd2;
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.title {
  color: #113357;
  font-family: Sora;
  font-size: 28px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.152px;
  margin-bottom: 10px;
}

.buttonContainer {
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  flex: 1;
  gap: 10px;
}

.permissionContainer {
  display: flex;
  flex-direction: column;
}

.permissionContainer p {
  color: #113357;
  font-family: Sora;
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 8px;
}

.permissionContainer .permissions {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.permissionContainer label {
  display: flex;
  align-items: center;
  gap: 10px;
  color: #113357;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.3px;
}

.permissionContainer label span {
  color: #848199;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.3px;
}

.permissionContainer input[type="radio"] {
  margin: 0;
}

.permissionContainer label:hover {
  cursor: pointer;
}

.error {
  color: #f05c30;
  font-size: 12px;
}

.creationFailedContainer {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  margin: auto;
}

.creationFailedContainer img {
  width: 295px;
  height: 203px;
  margin-left: 64px;
  margin-bottom: 28px;
}

.subtitle {
  color: #113357;
  text-align: center;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
