.container {
  display: flex;
  gap: 34px;
  padding: 90px 45px;
  grid-area: previewModal;
  background: white;
  width: 930px;
  justify-content: space-between;
}

.content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 12px;
}

.title {
  font-family: "Sora";
  font-style: normal;
  font-weight: 700;
  font-size: 26.4059px;
  line-height: 35px;
  letter-spacing: -0.11505px;
  color: #113357;
}

.description {
  font-family: "Sora";
  font-size: 14px;
  font-weight: 400;
  color: #113357;
}

.previewImageContainer {
  grid-area: preview;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  /* gap: 8px; */
}

.previewImage {
  position: relative;
  background-color: #f6f9fc;
  /* width: 423px;
  height: 278px; */
  flex: 1 1 100%;
}

.previewImage img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 423px;
  max-height: 278px;
}

.upgradeFooter {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-self: center;
  background: #f5efff;
  border-radius: 4px;
  width: 100%;
  /* height: 32px; */
  opacity: 0.8;
}

.upgradeFooter:hover {
  opacity: 1;
}

.upgradeFooterContent {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  width: 100%;
}

.upgradeText {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  color: #7517f8;
}

.upgradeLink {
  composes: upgradeText;
  text-decoration: underline;
  cursor: pointer;
}

.previewContainer {
  position: relative;
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: 1fr;
  grid-template-areas: "preview";
  min-width: 520px;
  height: 370px;
  border: 1px solid #dadce0;
  overflow: hidden;
}

.canvasPreview {
  grid-area: preview;
}

.copyLinkButton {
  position: absolute;
  right: 22px;
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 26px 24px;
}
