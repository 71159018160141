.container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  border-bottom: 1px solid #ebedf3;
  background-color: white;
}

.right {
  display: inline-flex;
  gap: 20px;
  align-items: center;
  position: fixed;
  right: 1vw;
}

.header {
  height: 48px;
  padding: 0px 20px;
  display: flex;
  align-items: center;
}

.toolbar {
  background-color: var(--dark);
  color: white;
  height: 48px;
  display: flex;
  padding: 0px 20px;
  gap: 8px;
  align-items: center;
  font-size: 14px;
  font-weight: 300;
}

.toolBarWarning {
  composes: toolbar;
  background-color: #ff3b77;
}

.toolbar img {
  width: 16px;
}

.closeToolbar {
  width: 10px !important;
  cursor: pointer;
}

.content {
  flex: 1;
  display: flex;
  justify-content: flex-end;
}

.contentButtons {
  gap: 6px;
  display: flex;
}

.inviteButton {
  width: 100px;
  background: var(--blue);
  text-align: center;
  vertical-align: middle;
  line-height: 40px;
  border-radius: 4px;
  gap: 6px;
  display: flex;
  justify-content: center;
}

.inviteButton:hover {
  opacity: 0.8;
  cursor: pointer;
}

.inviteIcon {
  width: 18px;
  height: auto;
}

.inviteText {
  color: white;
}

.toolbar .description {
  color: #b7bbc7;
  white-space: nowrap;
}

.toolBarWarning .description {
  color: #ffffff;
  white-space: nowrap;
}

.moreInfo {
  text-decoration: underline;
  white-space: nowrap;
  cursor: pointer;
  color: #1973ff;
}

.moreInfo:hover {
  opacity: 0.5;
}

.notification {
  display: flex;
  align-items: center;
  color: white;
  height: 44px;
}

.restrictedButtons {
  display: flex;
  gap: 6px;
  font-family: "Sora";
  margin-left: 1vw;
}

.ctaWrapper {
  padding-left: 10px;
}

.verticalDivider {
  border-left: 1px solid #ebedf3;
  height: 24px;
  margin: 0px 16px;
}
