.closeIcon {
  position: absolute;
  cursor: pointer;
  color: #848199;
  top: 13px;
  right: 13px;
  width: 16px;
  height: 16px;
}

.closeIcon:hover {
  color: #c2c3c9;
}

.deleteContainer {
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
}

.deleteContainer .title {
  color: #113357;
  text-align: center;
  font-family: Sora;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.152px;
}

.deleteContainer .subtitle {
  color: #113357;
  text-align: center;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 29.2px;
  letter-spacing: -0.125px;
}

.deleteContainer .buttonsContainer {
  display: flex;
  gap: 20px;
}
