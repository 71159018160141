.container {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: row-reverse;
  width: 90vw;
  max-width: 893px;
  height: auto;
  max-height: 90vh;
}

.smallContainer {
  composes: container;
  grid-template-columns: auto;
  grid-template-rows: auto;
  grid-template-areas: "left-section";
  width: 575px;
  top: 50%;
}

.sectionColumn {
  display: flex;
  flex-direction: column;
}

.flexContainer {
  display: flex;
  gap: 12px;
  align-items: center;
}

.leftSection {
  background: white;
  min-width: 532px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.rightSection {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  gap: 10px;
  background: #f6f9fc;
  border-left: 0.612028px solid #cbcfd2;
  padding: 28px;
}

.rightSectionContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 62px;
}

.timeLineWrapper {
  display: flex;
  flex-direction: column;
  align-items: inherit;
  gap: 119px;
}

.timeLineContainer {
  width: 516px;
  height: 347px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: white;
}

.timeLineContainerContent {
  margin-left: 36px;
  margin-top: 61px;
}

.timelineGrid {
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: 33.3% 33.3% 33.3%;
  height: 242px;
  width: fit-content;
}

.timeline {
  height: 162px;
  background-color: #c4c4c4;
  width: 1px;
  grid-column-start: 1;
  grid-row-start: 1;
}

.timeLineItem {
  display: flex;
  gap: 16px;
  grid-column-start: 1;
  grid-row-start: 1;
  position: relative;
  right: 17.5px;
}

.timeLineCircle {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 35px;
  height: 35px;
  background-color: #97e2de;
  border-radius: 50%;
}

.textFlexWrapper {
  display: flex;
  flex-direction: column;
  height: fit-content;
}

.nextPeriodLineItem {
  display: flex;
  gap: 5px;
}

.timeLineItemText {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 22px;
  /* or 157% */
  color: #0b2642;
}

.timeLineItemTextBold {
  font-family: "Sora";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 22px;
}

.checkoutWrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  flex-grow: 1;
  max-height: max-content;
  padding: 40px;
  padding-bottom: 0;
}

.checkoutContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 320px;
}

.checkoutHeader {
  font-family: "Sora";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 30px;
  letter-spacing: 0.152063px;
  color: #113357;
}

.infoContainer {
  display: flex;
  flex-direction: column;
  gap: 9px;
  margin-top: 18px;
}

.infoLine {
  display: flex;
  align-items: center;
  gap: 12.5px;
}

.infoLineText {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;

  display: flex;
  align-items: center;

  color: #113357;
}

.checkoutHeaderSkeleton {
  width: 88%;
  height: 36px;
  margin-left: 37px;
}

.periodPickerSkeletion {
  width: 50%;
  margin-left: 25%;
}

.paymentFormHeader {
  composes: checkoutHeader;
  margin-top: 44px;
}

.paymentTypeContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 16px;
  border-top: 1px solid #dadce0;
  border-bottom: 1px solid #dadce0;
  min-height: 166px;
  margin-top: 10px;
}

.paymentTypeInnerContainer {
  display: flex;
  gap: 6px;
  cursor: pointer;
}

.radioButton {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  border: 1px solid #aaaaaa;
  position: relative;
  top: 4px;
}

.radioButtonSelected {
  composes: radioButton;
  background-color: #1973ff;
  border: unset;
}

.radioButtonInnerCircle {
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background-color: white;
}

.paymentType {
  display: flex;
  flex-direction: column;
  gap: 3px;
}

.paymentTypeText {
  font-family: "Sora";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 22px;
  color: #113357;
}

.priceContainer {
  display: flex;
  gap: 5px;
}

.paymentTypePrice {
  composes: paymentTypeText;
  font-weight: 400;
}

.promotionTag {
  display: flex;
  align-items: center;
  justify-content: center;
  /*width: 183px;*/
  height: 20px;
  background: #00b875;
  padding-left: 8px;
  padding-right: 8px;

  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  display: flex;
  align-items: center;
  color: #ffffff;
}

.promotionContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 17px;
  width: fit-content;
  margin-top: 16px;
}

.promotionInput {
  width: 160px;
  height: 34px;
  background: #ffffff;
  border: 1.10596px solid #e3e8ee;
  outline: none;
  padding-left: 12px;
}

.promotionInput::placeholder {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #848199;
}

.applyPromotion {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 28px;
  display: flex;
  align-items: center;
  color: #1973ff;
  cursor: pointer;
}

.invoceDetailsContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
  gap: 13px;
}

.lineItem {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.flexColumnContainer {
  display: flex;
  flex-direction: column;
  gap: 6px;
  position: relative;
  width: 93%;
}

.nextMonthLineItem {
  composes: lineItem;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #113357;
}

.nextMonthLineItemContainer {
  display: flex;
  flex-direction: column;
}

.todayLineItem {
  display: flex;
  gap: 7px;
  white-space: nowrap;
}

.usersLineItem {
  composes: nextMonthLineItem;
  color: #848199;
}

.freeLineItem {
  composes: nextMonthLineItem;
  color: #00b875;
  font-weight: 600;
}

.continueButton {
  justify-content: center;
  width: 100%;
  height: 36px;
  background: #1973ff;

  font-family: "Sora";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.124302px;
  color: #ffffff;

  border: none;
  outline: none;
  cursor: pointer;
  opacity: 1;
  z-index: 1001;
}

.continueButton:hover {
  opacity: 0.8;
}

.continueButtonSkeleton {
  width: 93%;
  height: 36px;
  margin-top: 12px;
}

.plansContainer {
  display: flex;
  align-items: center;
  gap: 19px;
  margin-top: 17px;
}

.planContainer {
  display: flex;
  flex-direction: column;
  width: 242.62px;
  height: 257px;
  border: 0.903465px solid #d0d0d9;
  border-radius: 3.61386px;
  cursor: pointer;
}

.planContainerSkeleton {
  width: 242.62px;
  height: 257px;
}

.planContainer:hover {
  border: 1px solid #1973ff;
}

.selectedPlanContainer {
  composes: planContainer;
  border: 1px solid #1973ff;
}

.currentPlanContainer {
  composes: planContainer;
  cursor: default;
}

.currentPlanContainer:hover {
  border: 0.903465px solid #d0d0d9;
}

.planPriceContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 24.95%;
  /* background-color: #F8FAFD;
  border-bottom: 0.642139px solid #EBEDF3;;*/
  gap: 11px;
}

.planPrice {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 27.1374px;
  line-height: 35px;
  color: #0b2642;
}

.selectedPlanPrice {
  composes: planPrice;
  color: #1973ff;
}

.planPricePerUser {
  display: flex;
  flex-direction: column;
  gap: 2px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #848199;
}

.planFeaturesContainer {
  display: flex;
  height: 70%;
  background: #f8fafd;
}

.selectedPlanFeaturesContainer {
  composes: planFeaturesContainer;
  background-color: #eaeeff;
  /*background-color: #F5EFFF;*/
}

.planFeaturesList {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-left: 14.8px;
}

.planFeature {
  display: flex;
  align-items: center;
  gap: 9px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 0.272588px;
  color: #0b2642;
}

.planTypeContainer {
  display: flex;
  align-items: center;
  gap: 10px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  letter-spacing: 0.27104px;
  color: #0b2642;
  margin-left: 14.8px;
  height: 28%;
}

.currentPlan {
  composes: planTypeContainer;
  color: #cecdd6;
}

.planOption {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  border: 0.903465px solid #d0d0d9;
}

.selectedPlanOption {
  composes: planOption;
  border: 0.903465px solid #1973ff;
}

.selectedInnerCircle {
  border-radius: 50%;
  width: 13.55px;
  height: 13.55px;
  background-color: #1973ff;
  position: relative;
  left: 0.1px;
}

.paymentDetailsContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.paymentDetails {
  display: flex;
  flex-direction: column;
  width: -webkit-fill-available;
}

.seperator {
  width: 93%;
  border: 0.5px solid #cbcfd2;
  margin-top: 23px;
}

.back {
  display: flex;
  align-items: center;
  gap: 10.75px;
  width: fit-content;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.151852px;
  color: #0b2642;
  cursor: pointer;
}

.headerContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 95%;
  margin-top: 15px;
  position: relative;
  left: 15px;
}

.headerContainerCloseOnly {
  composes: headerContainer;
  justify-content: end;
}

.seatsSelectionContainer {
  display: flex;
  align-items: center;
  width: 100%;
  min-height: 79px;
  position: relative;
  top: 4px;

  color: #113357;
  font-size: 14px;
  font-family: Sora;
  font-style: normal;
  font-weight: 700;
  line-height: 29.809px;
  letter-spacing: -0.098px;
}

.seatsSelectionContent {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;
}

.dropDownContainer {
  font-family: "Sora";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 13px;
  display: flex;
  align-items: center;
  letter-spacing: 0.219412px;
  color: #113357;
}

.invoiceBreakDownText {
  color: #848199;
  font-size: 12px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  position: relative;
  bottom: 13px;
}

.image {
  height: 85%;
  width: 100%;
}

.rightSectionTitle {
  color: #113357;
  font-size: calc(0.9 * 14px);
  font-family: Sora;
  font-style: normal;
  font-weight: calc(0.9 * 600);
  text-align: center;
}

.checkoutSkeleton {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 10px;
  justify-content: space-around;
  position: relative;
  right: 8px;
}

.checkoutSkeletonContainer {
  composes: checkoutSkeleton;
}

.checkoutSkeletonButton {
  composes: checkoutSkeleton;
  position: relative;
  right: 8px;
  margin-top: 122px;
}

.paymentMessage {
  display: none;
  color: rgb(105, 115, 134);
  font-size: 14px;
  line-height: normal;
  text-align: center;
  color: red;
  font-family: Poppins;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0.219px;
  min-height: 54px;
  max-height: fit-content;
  margin-top: 12px;
  width: 93%;
}

.showPaymentMessage {
  display: flex;
}

.ctaButton {
  z-index: 999;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 40px;
  height: max-content;
}

.ctaWithMessage {
  padding: 20px 40px;
}

@media (max-height: 510px) {
  .ctaButton {
    border-top: 1px solid #dadce0;
    box-shadow: 0 -10px 15px -5px #dadce0;
    margin: 0;
    padding: 20px 40px;
  }
  .checkoutWrapper {
    overflow-y: auto;
    overflow-x: hidden;
  }
}
