.container {
  position: absolute;
  left: 50%;
  top: 50%;

  transform: translate(-50%, -50%);
  pointer-events: visible;

  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.200691);
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  z-index: 1;
  width: 90vw;
  max-width: 930px;
  height: 90vh;
  max-height: 700px;
}

.title {
  text-align: center;
  padding: 16px;
  font-size: 24px;
  font-weight: 700;
}

.header {
  padding: 17px;
  display: flex;
  flex-direction: row;
  gap: 17px;
  border-bottom: 1px solid #dadce0;
}

.searchContainer {
  background-image: url("/images/magnifying-glass.svg");
  background-repeat: no-repeat;
  background-position: 10px;
  background-size: 14px;
  display: flex;
  border: 0.5px solid #dadce0;
  height: 36px;
  width: 333px;
  padding: 0 32px;
}

.searchContainer input {
  border: 0;
  outline: none;
  height: 34px;
  flex-grow: 1;
  font-size: 12px;
  color: #113357;
}

.unlockButton {
  display: flex;
  align-items: center;
  gap: 7px;
  padding: 7px;
  height: 36px;
  border-radius: 2px;
  border: 1px solid var(--blue);
}

.unlockButton:hover {
  cursor: pointer;
  background-color: var(--light-blue);
}

.unlockButton span {
  font-size: 12px;
  font-weight: 600;
  color: var(--blue);
}

.content {
  display: flex;
  flex-direction: row;
  overflow: hidden;
  justify-content: stretch;
  height: 100%;
}

.tags {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 17px;
  gap: 8px;
  color: #113357;
}

.tagsContainer {
  width: 230px;
  border-right: 1px solid rgba(218, 220, 224, 1);
}

.tag {
  padding: 7px 13px;
  font-family: Poppins, sans-serif;
  font-weight: 400;
  font-size: 14px;
}

.tag:hover {
  cursor: pointer;
  background-color: #e6f1ff;
}

.tag.selected {
  background-color: #e6f1ff;
  font-weight: 600;
}

.separator {
  min-height: 1px;
  height: 1px;
  background-color: #dadce0;
  margin: 10px;
}

.templates {
  display: flex;
  row-gap: 24px;
  flex-flow: row wrap;
  column-gap: 23px;
  justify-content: flex-start;
}

.templatesScrollContainer {
  width: calc(100% - 230px);
}

.templatesContainer {
  display: flex;
  flex-direction: column;
  padding: 20px 32px;
  gap: 70px;
  flex: 1;
}

.templatesContainer,
.tags {
  overflow-x: hidden;
}

.templatesSection {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.sectionHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.sectionTitle {
  color: var(--dark);
  font-family: Sora, sans-serif;
  font-weight: 700;
  font-size: 24px;
}

.seeAll {
  font-family: Poppins, sans-serif;
  font-weight: 400;
  font-size: 14px;
  color: var(--blue);
}

.seeAll:hover {
  cursor: pointer;
  text-decoration: underline;
  opacity: 0.5;
}

.templateSpacer {
  flex: 1;
}

.closePreview {
  position: absolute;
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 24px;
  color: var(--dark);
}

.closePreview:hover {
  opacity: 0.5;
  cursor: pointer;
}

.closePreview span {
  font-size: 14px;
  font-weight: 400;
}

.closeIcon {
  position: absolute;
  cursor: pointer;
  color: #848199;
  top: 13px;
  right: 13px;
  width: 16px;
  height: 16px;
}

.closeIcon:hover {
  color: #c2c3c9;
}

.tagsSkeleton {
  height: 30px;
}

.templateSkeletonContainer {
  display: flex;
  row-gap: 24px;
  flex-flow: row wrap;
  /* column-gap: 23px; */
  justify-content: space-between;
}

.templateSkeleton {
  width: 191px;
  height: 141px;
}

.previewContainer {
  composes: container;
  height: unset;
}
