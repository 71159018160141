.container {
  display: flex;
  flex-direction: column;
  min-height: 31px;
  align-content: stretch;
  width: 100%;
  padding: 6px 5px 4px;
  max-height: 167px;
  overflow-y: auto;
  gap: 6px;
  background: #fff;
}

.valuesContainer {
  display: flex;
  align-content: flex-start;
  flex-direction: column;
  flex-flow: row wrap;
  gap: 6px;
  max-width: 450px;
  outline: none;
}

.input {
  outline: none;
  border: none;
  flex: 1;
  padding: 0;
}

.tag {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  padding: 2px 8px;
  font-size: 12px;
  height: 24px;
  background: #e6f1ff;
  color: #113357;
}

.tag:hover {
  cursor: pointer;
}

.tag.invalid {
  background: #fdd5d3;
}

.tag.focused {
  background: #d1e3ff;
  border: 1px solid #1973ff;
}

.tag.invalid.focused {
  background: none;
  border: 1px solid #dadce0;
}

.tag.invalid.highlight {
  background: rgba(255, 217, 205, 0.7);
  border: 1px solid #f05c30;
  animation: shake 0.2s;
  animation-iteration-count: 3;
}

@keyframes shake {
  0% {
    transform: translate(1px, 1px);
  }

  50% {
    transform: translate(-1px, -1px);
  }

  100% {
    transform: translate(1px, 1px);
  }
}
