.container {
  position: absolute;
  left: 50%;
  top: 50%;

  transform: translate(-50%, -50%);
  pointer-events: visible;

  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.200691);
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: stretch;

  width: 930px;
  height: 600px;

  z-index: 1;
}

.closePreview {
  position: absolute;
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 24px;
  color: var(--dark);
}

.closePreview:hover {
  opacity: 0.5;
  cursor: pointer;
}

.closePreview span {
  font-size: 14px;
  font-weight: 400;
}

.closeIcon {
  position: absolute;
  cursor: pointer;
  top: 13px;
  right: 13px;
  width: 16px;
  height: 16px;
}

.closeIcon:hover {
  background: #eff1f5;
  border-radius: 4px;
}

.previewContainer {
  composes: container;
  height: unset;
}
