.closeIcon :hover {
  cursor: pointer;
}

.backArrow {
  display: flex;
  align-items: center;
  gap: 6px;
  font-size: 12px;
}

.backArrow :hover {
  cursor: pointer;
}
