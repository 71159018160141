.centerScreen {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  min-height: 100vh;
}

.mainLayout {
  position: relative;
  width: 961px;
  display: grid;
  grid-template-columns: 4fr 5.6fr;
  box-shadow: 0px 7px 25px 0px #0000004d;
  background-color: white;
}

.mainLayout > * {
  height: 601px;
}

.upgradeModal {
  color: var(--text);
  padding: 33px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  font-size: 14px;
  font-weight: 400;
}

/* reset padding and margin for h1,h2, section */
.upgradeModal > * {
  padding: 0;
  margin: 0;
  text-align: start;
}

.upgradeModal h1 {
  font-family: Sora;
  font-size: 24px;
  font-weight: 700;
  line-height: 30px;
  letter-spacing: 0.15px;
  text-align: left;
  color: var(--text);
}

.upgradeModal h2 {
  font-size: 14px;
  font-weight: 600;
  line-height: 24px;
  text-align: left;
  color: var(--text);
  display: flex;
  align-items: center;
  gap: 1em;
}

.upgradeModal section {
  font-weight: 400;
  font-size: 14px;
  line-height: 1.5;
  text-align: start;
}

.upgradeModal b {
  font-size: 14px;
  font-weight: 600;
  line-height: 24px;
}

.upgradeModal ul {
  list-style-image: url("/images/feature-check-i.svg");
  /* list-style-type: circle; */
  list-style-position: inside;
}

.upgradeModal ul li {
  line-height: 2;
}

.upgradeModal .hline {
  height: 1px;
  width: 100%;
  border: none;
  background-color: #dadce0;
}

.cancelContainer {
  color: var(--text);
}

.upgradeImage {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
}

.newUpgradeImage {
  background-color: transparent;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 566px;
}

.newUpgradeImage > img {
  width: 100%;
}

/********************  Close button **********************/

.closeButton {
  position: absolute;
  top: 14px;
  right: 14px;

  border-radius: 50%;
  padding: 0.75ch;
  width: 1.5rem;
  height: 1.5rem;
  display: grid;
  place-content: center;
  font-size: 1em;
  line-height: 1;
  outline: none;
  border: none;
  background: none;
  cursor: pointer;
  color: #848199;
}

.closeButton:hover {
  color: var(--dark-blue);
}

.closeButton::after {
  content: "\2715";
}

.upgradeButton {
  align-self: center;
  width: 100%;
  margin-top: auto;
}
