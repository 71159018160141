.container {
  display: flex;
  flex-direction: column;
  gap: 40px;

  background-color: white;
  min-height: 300px;
  padding: 40px;
  margin-top: 20px;
}

.header {
  text-align: center;
  font-weight: 600;
  font-size: 1.2em;
}

.buttons {
  display: flex;
  justify-content: center;
  gap: 20px;
}

.buttonContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
}

.mondayPickerContainer {
  margin-top: 30px;
  display: flex;
  flex-direction: column;
}

.mondayPickerHeader {
  padding: 20px;
  text-align: center;
}

.mondayPickerContent {
  padding: 30px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.mondayPickerStep {
  display: flex;
  gap: 20px;
  padding: 20px 0;
}

.mondayPickerStepLabel {
  width: 200px;
}

.mondayPickerStepContent {
  padding-right: 100px;
  flex: 1;
}

.mondayPickerFooter {
  display: flex;
  justify-content: center;
  padding: 30px;
}

.mondayColumnPicker {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
}

.mondayColumnPickerLabel {
  right: 0;
  font-size: 16px;
  color: #848199;
}

.mondayPickerLiveContainer {
  display: flex;
  align-items: top;
  gap: 20px;
}

.mondayPickerLiveDescription {
  display: flex;
  flex-direction: column;
  font-size: 14px;
}

.mondayPickerLiveTitle {
  font-size: 16px;
  font-weight: 600;
}

.importFromMondayRadioBlock {
  position: relative;
}

.mondayLogo {
  position: absolute;
  width: 30px;
  right: 15px;
  top: 15px;
  pointer-events: none;
}

.mondayPickerTitle {
  font-family: Sora;
  font-size: 24px;
  font-weight: 700;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
}

.mondayLogoBig {
  height: 30px;
}
