.container {
  width: 450px;
  border-radius: 10px;
  filter: drop-shadow(0px 4px 8px rgba(0, 0, 0, 0.200691));
  background-color: white;
  position: absolute;
  left: 50%;
  top: 50%;
  gap: 20px;
  transform: translate(-50%, -50%);
  pointer-events: visible;
  overflow: hidden;
  padding: 20px;

  display: flex;
  flex-direction: column;
  align-items: center;
}

.title {
  font-size: 18px;
  font-weight: 500;
}
