.toolbar {
  background-color: var(--dark);
  color: white;
  height: 100%;
  display: flex;
  padding: 0px 20px;
  gap: 8px;
  align-items: center;
  font-size: 14px;
  font-weight: 300;
  flex-grow: 1;
}

.toolbar img {
  width: 16px;
}

.toolbar .description {
  color: #ffffff;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  letter-spacing: 0.266px;
  white-space: nowrap;
  margin-right: 12px;
}

.toolBarWarning .description {
  color: #ffffff;
  white-space: nowrap;
}

.moreInfo {
  text-decoration: underline;
  white-space: nowrap;
  cursor: pointer;
}
