.profile {
  width: 32px;
  height: 32px;
  border-radius: 50%;
}

.profile:hover {
  cursor: pointer;
}

.profile img {
  width: 32px;
  height: 32px;
  border-radius: 50%;
}

.border img {
  border: 2px solid #f5b53d;
}
