.container {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 703px;
  height: 543px;
  background: #ffffff;
  box-shadow: 0px 7.59259px 25.3086px rgba(0, 0, 0, 0.3);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.flexColumn {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.content {
  composes: flexColumn;
  width: 100%;
}

.header {
  composes: flexColumn;
  gap: 6px;
}

.headerTitle {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 30px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.151852px;
  color: #0b2642;
}

.headerDescription {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  letter-spacing: 0.151852px;
  color: #0b2642;
}

.stepsContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f8fafd;
  border: 0.711458px solid #ebedf3;
  width: 100%;
  height: 233px;
  margin-top: 10px;
  gap: 33px;
}

.stepContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.stepTitle {
  display: flex;
  align-items: baseline;
  justify-content: center;
  gap: 10px;
}

.stepText {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.151852px;
  color: #0b2642;
}

.stepNumber {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 26px;
  line-height: 48px;
  color: #7517f8;
}

.couponContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 8px;
  margin-top: 30px;
}

.stepImage {
}

.validUntill {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  text-align: center;
  letter-spacing: 0.151852px;
  color: #848199;
}

.copyCouponFlex {
  display: flex;
  justify-content: center;
  align-items: center;
}

.couponInput {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 190px;
  height: 32px;
  background: #ffffff;
  border: 1px dashed #7517f8;
  border-radius: 2px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
}

.couponInput:focus {
  outline: none;
}

.couponCode {
  composes: couponInput;
  line-height: 21px;
  text-align: center;
  letter-spacing: 0.151852px;
  color: #0b2642;
}

.couponCopied {
  composes: couponInput;
  font-size: 12px;
  line-height: 18px;
  text-align: center;
  letter-spacing: 0.3px;
  color: #7517f8;
}

.copyCoupon {
  position: relative;
  right: 4px;
  width: 93px;
  height: 33px;
  background: #7517f8;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.156049px;
  border: none;
  outline: none;
  color: #ffffff;
}

.copyCoupon:hover {
  opacity: 0.9;
}

.continueToCheckout {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  gap: 7px;
}

.copyCouponError {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  text-align: center;
  letter-spacing: 0.151852px;
  color: red;
  opacity: 1;
}

.copyCouponErrorHidden {
  composes: copyCouponError;
  opacity: 0;
}

.cta {
  width: 180px;
  height: 33px;
  background: #7517f8;
  border-radius: 4px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.11307px;
  color: #ffffff;
  cursor: pointer;
  border: none;
  outline: none;
}

.cta:hover {
  opacity: 0.9;
}

.ctaShaked {
  composes: cta;
  animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97);
}

.closeIcon {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}

@keyframes shake {
  10%,
  90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%,
  80% {
    transform: translate3d(2px, 0, 0);
  }

  30%,
  50%,
  70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%,
  60% {
    transform: translate3d(4px, 0, 0);
  }
}
