.container {
  padding: 138px 20px 20px 20px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.titleContainer {
  display: flex;
  flex-direction: column;
  gap: 14px;
  align-items: center;
  margin-bottom: 100px;
}

.title {
  all: unset;
  color: #113357;
  font-family: Sora;
  text-align: center;
  font-size: 40px;
  font-style: normal;
  font-weight: 500;
  line-height: 57.134px;
  letter-spacing: 0.254px;
}

.chooseTemplateCardsContainer {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 40px;
  align-items: center;
  justify-content: center;
  margin-bottom: 60px;
}

@media (max-width: 1000px) {
  .title {
    font-size: 36px;
    line-height: 42px;
  }

  .chooseTemplateCardsContainer {
    margin-bottom: 60px;
  }

  .titleContainer {
    margin-bottom: 60px;
  }

  .container {
    padding-top: 100px;
  }
}

@media (max-height: 650px) {
  .container {
    padding-top: 60px;
  }

  .titleContainer {
    margin-bottom: 30px;
  }

  .chooseTemplateCardsContainer {
    margin-bottom: 30px;
  }
}

@media (max-height: 500px) {
  .title {
    font-size: 34px;
    line-height: 40px;
  }

  .container {
    padding-top: 30px;
  }

  .titleContainer {
    margin-bottom: 30px;
  }

  .chooseTemplateCardsContainer {
    margin-bottom: 30px;
  }
}
