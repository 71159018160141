.coverContainer {
  display: flex;
  flex-direction: column;
  gap: 70px;
  flex: 1;
}

.cover {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 25px;
  height: 100%;
}

.emptyState {
  justify-content: space-between;
}

.emptyStateNoTemplatesImageCover {
  background-image: url(/images/no-personal-templates.png);
  background-size: cover;
  background-position: center;
}

.upgradeStateImageCover {
  background-image: url(/images/upgrade-from-template-lib.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.upgradeScreen {
  justify-content: center;
  padding-top: 100px;
}

.cover .title {
  color: #113357;
  text-align: center;
  font-family: Sora;
  font-size: 25px;
  font-style: normal;
  font-weight: 600;
  letter-spacing: -0.098px;
  padding: 16px;
}

.cover .subtitle {
  color: #113357;
  text-align: center;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: -0.098px;
}

.noSearchResults {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
  height: 100%;
  width: 100%;
}

.noSearchResults span {
  font-size: 14px;
  font-weight: 400;
  text-align: center;
}

.noSearchResults img {
  width: 115px;
  height: 100px;
}
