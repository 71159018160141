.container {
  width: 400px;

  position: absolute;
  left: 50%;
  top: 50%;

  transform: translate(-50%, -50%);
  pointer-events: visible;

  filter: drop-shadow(0px 4px 8px rgba(0, 0, 0, 0.200691));
  background-color: white;
  gap: 21px;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  min-height: 189px;
}

.header {
  padding: 15px 20px;
  display: flex;
  color: var(--text);
  font-family: Sora;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 29.809px;
  letter-spacing: -0.098px;
}

.seatsEditorContainer {
  min-height: 52px;
  width: 100%;
  background: #f8fafd;
  border: 0.711458px solid #ebedf3;
  box-sizing: border-box;
  border-radius: 0px;
  display: flex;
  justify-content: flex-start;
}

.seatsEditorContent {
  display: flex;
  gap: 10px;
  justify-content: center;
  align-items: center;
  margin-left: 21px;
}

.seatsEditorContent input {
  font-family: Poppins;
  border: 1.01238px solid #ebedf3;
  border-radius: 4px;
  font-size: 14px;
  outline-style: none;
  height: 31px;
  width: 62px;
  text-align: center;
}

.paymentDetailsContainer {
  min-height: 114px;
  width: 100%;
  background: #f8fafd;
  border: 0.711458px solid #ebedf3;
  box-sizing: border-box;
  border-radius: 0px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.paymentRow {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 21px 0 21px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.142292px;
  color: var(--text);
  opacity: 0.5;
}

.totalPaymentRow {
  composes: paymentRow;
  opacity: 1;
  font-weight: 600;
}

.separator {
  background: #f8fafd;
  border-top: 0.711458px solid #ebedf3;
  height: 4px;
}

.inviteField input:focus {
  border: 1px solid #c6c6c6;
}

.buttonsContainer {
  display: flex;
  justify-content: flex-end;
  gap: 13px;
  margin-right: 21px;
  padding-bottom: 21px;
}

.errorContainer {
  text-align: center;
}

.errorText {
  justify-content: center;
  font-family: Poppins;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  align-items: center;
  color: #ff3b77;
}

.adminLink {
  composes: errorText;
  cursor: pointer;
  text-decoration: underline;
}

.seatsStatusContainer {
}

.seatStatusContent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 21px;
  gap: 6px;
}

.seatsStatusText {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.124839px;
  color: #0b2642;
}

.progressContainer {
  width: 90%;
  height: 8px;
  background: #e6e9ef;
  border-radius: 4px;
}

.progressBar {
  background: #7517f8;
  height: 8px;
}
