.button {
  all: unset;
  position: relative;
  color: #fff;
  text-align: center;
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.114px;
  width: 230px;
  height: 50px;
  border-radius: 4px;
  background-color: #1973ff;
  cursor: pointer;
  transition: all 0.2s;
}

.button:hover {
  background-color: #0f5ff0;
}

.button:disabled {
  cursor: default;
  pointer-events: none;
}

.button:focus {
  outline: none;
}

.disabled {
  background-color: #dcdfec;
}

.shimmer {
  position: absolute;
  z-index: 99;
  inset: 0;
  animation-duration: 2.2s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: shimmer-animation;
  animation-timing-function: linear;
  background: linear-gradient(45deg, rgba(0, 0, 0, 0) 8%, rgba(240, 240, 240, 0.5) 18%, rgba(0, 0, 0, 0) 33%);
  background-size: 1200px 100%;
}

@-webkit-keyframes shimmer-animation {
  0% {
    background-position: -100% 0;
  }
  100% {
    background-position: 100% 0;
  }
}

@keyframes shimmer-animation {
  0% {
    background-position: -1200px 0;
  }
  100% {
    background-position: 1200px 0;
  }
}

@media (max-height: 500px) {
  .button {
    position: fixed;
    bottom: 10px;
  }
}
