.textarea {
  width: 100%;
  font-size: 14px;
  font-weight: 400;
  border: 0;
  outline: 0;
  padding: 8px;
  color: #323338;
  resize: none;
}
