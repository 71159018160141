.container {
  pointer-events: visible;
  filter: drop-shadow(0px 4px 8px rgba(0, 0, 0, 0.200691));
  z-index: 1000;
}

.dark {
  background-color: #0b2642;
  color: white;
}

.light {
  background: white;
  color: black;
}

.arrowContainer {
  position: relative;
  height: 10px;
}

.arrow {
  position: absolute;
  transform: translateY(-5px) rotate(45deg);
  width: 10px;
  height: 10px;
}

.light .arrow {
  background-color: white;
}
.dark .arrow {
  background-color: #0b2642;
}

.body {
  padding: 12px 18px 12px 18px;
  gap: 4px;
  display: flex;
  flex-direction: column;

  font-family: Poppins, Roboto, Arial;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 26px;
  white-space: nowrap;
  text-align: initial;
}

.line {
  display: flex;
  flex-direction: row;
  gap: 8px;
  cursor: pointer;
  align-items: center;
}

.disabled {
  color: #848199;
  opacity: 0.7;
  cursor: default;
  pointer-events: none;
}

/* Turn black color to #0B2642*/
.line:not(.disabled):not(.highlighted) {
  filter: invert(14%) sepia(11%) saturate(3602%) hue-rotate(171deg) brightness(92%) contrast(101%);
}
/* Turn black color to our blue */
.line:hover:not(.disabled):not(.highlighted) {
  filter: invert(31%) sepia(67%) saturate(2741%) hue-rotate(209deg) brightness(101%) contrast(105%);
}

.line:hover.highlighted span {
  filter: invert(31%) sepia(67%) saturate(2741%) hue-rotate(209deg) brightness(101%) contrast(105%);
}

/* Turn the white icon to black */
.light .line:not(.disabled):not(.highlighted) img {
  filter: invert(100%);
}

/* Turn the white icon to the disabled color #848199 */
.line.disabled > img {
  filter: invert(22%) sepia(6%) saturate(522%) hue-rotate(205deg) brightness(87%) contrast(85%);
}

.body > hr {
  position: relative;
  width: 100%;
  left: 0;
  right: 0;
  border: 0.86px solid #ebedf3;
}

.emptySpace {
  width: 17px;
}

.upgradeLink {
  font-family: Poppins;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  display: flex;
  align-items: center;
  text-decoration-line: underline;
  color: #7517f8;
  cursor: pointer;
}
