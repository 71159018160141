.container {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 623px;
  height: 324px;
  background: #ffffff;
  box-shadow: 0px 7.59259px 25.3086px rgba(0, 0, 0, 0.3);
  border-radius: 8px;
  display: flex;
}

.leftSide {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.rightSide {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #f5efff;
  border: 0.711458px solid #ebedf3;
  border-radius: 8px;
}

.rightContent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 20px;
  margin-left: 28px;
  margin-right: 41px;
}

.leftContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.promoTitile {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 58px;
  line-height: 87px;
  text-align: center;
  letter-spacing: 0.477867px;
  color: #0b2642;
}

.promoDescription {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  letter-spacing: 0.151852px;
  color: #0b2642;
}

.couponContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 8px;
  margin-top: 42px;
}

.validUntill {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  text-align: center;
  letter-spacing: 0.151852px;
  color: #848199;
}

.copyCouponFlex {
  display: flex;
  justify-content: center;
  align-items: center;
}

.couponInput {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 190px;
  height: 32px;
  background: #ffffff;
  border: 1px dashed #7517f8;
  border-radius: 2px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
}

.couponInput:focus {
  outline: none;
}

.couponCode {
  composes: couponInput;
  line-height: 21px;
  text-align: center;
  letter-spacing: 0.151852px;
  color: #0b2642;
}

.couponCopied {
  composes: couponInput;
  font-size: 12px;
  line-height: 18px;
  text-align: center;
  letter-spacing: 0.3px;
  color: #7517f8;
}

.copyCoupon {
  position: relative;
  right: 4px;
  width: 74px;
  height: 33px;
  background: #7517f8;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.156049px;
  border: none;
  outline: none;
  color: #ffffff;
}

.copyCoupon:hover {
  opacity: 0.9;
}

.cta {
  width: 180px;
  height: 33px;
  background: #7517f8;
  border-radius: 4px;
  margin-top: 52px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.11307px;
  color: #ffffff;
  cursor: pointer;
  border: none;
  outline: none;
}

.cta:hover {
  opacity: 0.9;
}

.rightTitle {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  letter-spacing: 0.3px;

  color: #0b2642;
}

.featuresList {
  display: flex;
  flex-direction: column;
  gap: 9px;
}

.featureContainer {
  display: flex;
  align-items: center;
  gap: 9.92px;
}

.featureText {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.301714px;
  color: #0b2642;
}

.closeIcon {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}
