.container {
  display: flex;
  align-items: center;
  gap: 10.75px;
  width: fit-content;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.151852px;
  color: #0b2642;
  cursor: pointer;
}
